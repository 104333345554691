import React from 'react';
import { Box, Typography, Container } from '@mui/material';

import { LinkButton } from '../../../shared/components/link-button';
import { useMediaHook } from '../../../shared/hooks/useMediaHook';

import { useStyles } from './style';

export const HeaderSection: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useMediaHook();
  const mapButtonTitle = isMobile ? 'Use Map' : 'Use Risk Mapping Tool';
  return (
    <Container component={'section'} sx={{ display: 'flex' }} className={classes.sectionWrap}>
      <Box component={'section'} className={classes.greyBox}>
        <Box component={'section'} className={classes.textWrapper}>
          <Typography variant={'h1'} component={'h1'}>
            Fusarium Head Blight Risk Mapping Tool
          </Typography>
          <Typography variant={'body1'} component={'p'}>
            Displaying the risk of FHB damage to Western Canadian cereals based on weather conditions.
          </Typography>
          <LinkButton link={'/map'} title={mapButtonTitle} />
        </Box>
      </Box>
    </Container>
  );
};
