import React, { useEffect, useState } from 'react';
import { Box, Container, SelectChangeEvent, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import moment from 'moment';

import { CommonChip } from '../../../shared/components/common-chip';
import { CommonAccordion } from '../../../shared/components/common-accordion';
import { useMediaHook } from '../../../shared/hooks/useMediaHook';

import { YearSelector } from './yearSelector';
import { useStyles } from './styles';
import { useFetchWeeklyReportsMutation, WeeklyReportsResponse } from '../../../store/api/api';
import { setWeeklyReportsDataAction } from '../../../store/weekly-reports/reducer';
import { weeklyReportsDataSelector } from '../../../store/weekly-reports/selectors';
import { LoaderComponent } from '../../../shared/components/loader-component';

export interface FilterData {
  province: string;
  year: string;
}

interface LocationState {
  provinceIndex: number;
}

export const WeeklyReport = () => {
  const location = useLocation();
  const locationState = location.state as LocationState;
  const [filter, setFilter] = useState<FilterData>({ province: provincesList[locationState ? locationState.provinceIndex : 1], year: '2022' });
  const classes = useStyles();
  const { isDesktop } = useMediaHook();

  const dispatch = useDispatch();
  const [fetchWeeklyReports, { isLoading }] = useFetchWeeklyReportsMutation();
  const data = useSelector(weeklyReportsDataSelector);

  useEffect(() => {
    const province = filter.province;
    fetchWeeklyReports({
      ...(province !== allProvinces && { province: province.toLowerCase() }),
      year: filter.year,
    })
      .unwrap()
      .then((weeklyReports) => {
        dispatch(setWeeklyReportsDataAction(weeklyReports));
      });
  }, [filter]);

  const onProvinceChange = (event: React.MouseEvent<HTMLElement>) => {
    const province = event.currentTarget.firstChild?.textContent ?? allProvinces;
    setFilter({ ...filter, province });
  };
  const onYearChange = (e: SelectChangeEvent<unknown>) => {
    const year = e.target.value as string;
    setFilter({ ...filter, year });
  };

  const chips = (
    <Box component={'section'} className={classes.chipsWrapper}>
      {provincesList.map((title, index) => {
        return (
          <CommonChip
            key={index}
            title={title}
            isActive={title === filter.province}
            label={title}
            onClick={onProvinceChange}
          />
        );
      })}
    </Box>
  );

  function getSubtitle(item: WeeklyReportsResponse) {
    const startDay = moment(item.date).subtract(6, 'd').format('DD');
    return moment(item.date).format(`MMMM ${startDay} - DD, yyyy`);
  }

  if (isLoading) return <LoaderComponent />;
  return (
    <Container component={'section'} className={classes.wrapper}>
      <Box component={'section'} className={classes.titleWrapper}>
        <Typography variant={'h3'} component={'h1'}>
          Weekly Report
        </Typography>
        {isDesktop && chips}
        <YearSelector year={filter.year} onChange={onYearChange} />
      </Box>
      {!isDesktop && chips}
      <Box component={'section'} className={classes.accordionBox}>
        {data.map((item, index) => (
          <CommonAccordion
            capitalize={true}
            key={index}
            title={item.province}
            subtitle={getSubtitle(item)}
            details={item.report}
          />
        ))}
      </Box>
    </Container>
  );
};

const allProvinces = 'All provinces';
export const provincesList = [allProvinces, 'Manitoba', 'Alberta', 'Saskatchewan'];
