import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    '&.MuiContainer-root': {
      padding: '79px 150px 0 136px',
      [theme.breakpoints.down(820)]: {
        padding: '25px 29px 0 20px',
      },
    },
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '22px',
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  chipsWrapper: {
    '& .MuiChip-root': {
      marginRight: '10px',
    },
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
    [theme.breakpoints.between(0, 'desktop')]: {
      margin: '20px 0 20px 0',
      overflowX: 'scroll',
      display: 'flex',
      width: '100%',
    },
    [theme.breakpoints.up('desktop')]: {
      margin: '0 0 0 47px',
      marginRight: 'auto',
      display: 'flex',
      justifyContent: 'space-around',
      minWidth: 400,
    },
  },
  datePeriodSelector: {
    marginLeft: 'auto',
    minWidth: 150,
  },
  accordionBox: {
    marginTop: 50,
    [theme.breakpoints.down('desktop')]: {
      marginTop: 0,
    },
  },
}));
