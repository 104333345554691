import React, { FC } from 'react';
import { useStyles } from './styles';

interface IProps {
  height: number,
  width: number,
  screenUrl: string,
  detailsData: Array<{
    icon: string,
    alt: string,
    title: string,
    detail: () => string | undefined,
  }>,
}

export const PdfComponent: FC<IProps> = ({ width, height, screenUrl, detailsData }) => {
  const classes = useStyles();

  return <div className={classes.container} style={{
    width: `${Math.round(width)}px`,
    height: `${Math.round(height)}px`,
  }}>
    <div
      className='title'
      style={{fontSize: '15px', marginTop: '-10px'}}
    >
      FDK Risk Map
    </div>
    <img className='map' src={screenUrl} alt={'map'}/>
    <div className='properties'>
      <div className='title'>Map Details</div>
      {detailsData.map((data, i) => {
        return <div className='details' key={i}>
          <span className='title'>{data.title}</span>
          <span className='property'>{data.detail()}</span>
        </div>
      })}
    </div>

    <div className='description'>
      <div className='title'>Disclaimer</div>
      <div className='text'>
        Efforts have been make to ensure accuracy of the information provided on this tool.
        However, models do not have 100% accuracy so you are encouraged to conduct field observations to
        verify the information provided on this tool.
      </div>
    </div>
  </div>
}
