import React from 'react';
import { Box, Modal, Typography } from '@mui/material';

import { CommonButton } from '../common-button';

import { modalContentStyles, useStyles } from './style';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onAccept?: () => void;
  title: string;
  description: string;
}

export const AgreementModalComponent: React.FC<ModalProps> = ({ open, onAccept, onClose, title, description }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className={classes.modal}
      disableScrollLock={true}
    >
      <Box component={'section'} className={classes.modalWrapper} sx={modalContentStyles}>
        <Typography variant='h2' component='h2'>
          {title}
        </Typography>
        <Typography variant='body2' component='p'>
          {description}
        </Typography>
        <Box component={'section'} display={'flex'} justifyContent={'flex-end'}>
          <CommonButton title={'Cancel'} onClick={onClose} style={{ backgroundColor: 'transparent' }} />
          <CommonButton title={'Accept'} onClick={onAccept} className={classes.acceptBtn} />
        </Box>
      </Box>
    </Modal>
  );
};
