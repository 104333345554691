import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';


export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
  },
}));
