import { createAction, createReducer } from '@reduxjs/toolkit'
import { GeoJsonObject } from 'geojson'
import { HeatmapResponse } from '../api/api'

export type RequestParams = {
  date: string
  cropType: string
  varietyType: string[]
  risk: string
}

interface HeatmapState {
  data: HeatmapResponse | null
  requestParams: RequestParams
}

const initialState: HeatmapState = {
  data: null,
  requestParams: {
    date: Date(),
    cropType: 'winterWheat',
    varietyType: [],
    risk: 'fhb'
  }
}

export const setHeatmapDataAction = createAction<HeatmapResponse>('heatmap/setData')
export const setHeatmapRequestParamsAction = createAction<RequestParams>('heatmap/setRequestParams')
export const clearHeatmapRequestParamsAction = createAction('heatmap/clearRequestParams')

export const heatmapReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setHeatmapDataAction, (state, action) => {
      state.data = action.payload
    })
    .addCase(setHeatmapRequestParamsAction, (state, action) => {
      state.requestParams = action.payload
    })
    .addCase(clearHeatmapRequestParamsAction, (state) => {
      state.requestParams = initialState.requestParams
    })
})
