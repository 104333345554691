import React from 'react';
import { Box, Chip, ChipProps } from '@mui/material';

import { useStyles } from './style';

interface ChipOwnProps {
  isActive?: boolean;
}

type Props = ChipOwnProps & ChipProps;

export const CommonChip: React.FC<Props> = ({ isActive = false, ...chipProps }) => {
  const classes = useStyles();
  return (
    <Box component={'section'} className={isActive ? classes.active : classes.disabled}>
      <Chip variant={isActive ? 'filled' : 'outlined'} {...chipProps} />
    </Box>
  );
};
