import React, { FC, useEffect, useState } from 'react';

import { Checkbox, Autocomplete, TextField, Typography, Popper } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClearIcon from '@mui/icons-material/Clear';
import { FieldAttributes, FormikProps } from 'formik';
import classNames from 'classnames';

import { clearIconStyle, useStyles } from './style';
import { PopperProps } from '@mui/material/Popper';

interface IProps {
  form: FormikProps<{ cropType: string }>;
  options: Array<{ value: string; label: string, cropType: string }>;
  field: FieldAttributes<{
    name: string;
    value: Array<{ value: string; label: string }>;
  }>;
}

export const CustomAutocomplete: FC<IProps> = ({ form, field, options }) => {
  const classes = useStyles();

  const { name, value: selected } = field;
  const { setFieldValue } = form;

  const [isOpened, setIsOpened] = useState(false);
  const [placement, setPlacement] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<typeof options>([]);

  const openHandler = () => setIsOpened(!isOpened);

  const icon = <CheckBoxOutlineBlankIcon />;
  const checkedIcon = <CheckBoxIcon sx={{ color: '#385E9D' }} />;

  useEffect(() => {
    !isOpened && setPlacement('');
  }, [isOpened]);

  useEffect(() => {
    const filteredOptions = options.filter(({cropType}) => cropType === form.values.cropType);
    setFilteredOptions(filteredOptions);
  }, [form.values]);

  useEffect(() => {
    const variety = options.find(({label}) => label === selected[0]);
    const { cropType } = form.values

    if(cropType && cropType !== variety?.cropType) {
      setFieldValue(name, []);
    }
  }, [form.values.cropType]);

  return (
    <Autocomplete
      PopperComponent={(props: PopperProps) => {
        return (
          <Popper
            {...props}
            keepMounted={true}
            modifiers={[
              {
                name: 'placementLogger',
                enabled: true,
                phase: 'main',
                fn({ state }) {
                  setPlacement(state.placement);
                },
              },
            ]}
            className={classNames(props.className, placement, classes.popperWrapper)}
          />
        );
      }}
      popupIcon={<KeyboardArrowDownIcon />}
      value={selected.map((value: string) => {
        return options.find((option) => option.label === value);
      })}
      className={classNames(
        placement,
        classes.wrapper,
        isOpened ? classes.opened : null
      )}
      disablePortal={true}
      multiple
      options={filteredOptions}
      disableClearable={true}
      getOptionLabel={(option) => option?.label}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
          <Typography component={'span'} className={classes.listText} variant={'body2'}>
            {option.label}
          </Typography>
        </li>
      )}
      ChipProps={{ deleteIcon: <ClearIcon style={clearIconStyle} /> }}
      onOpen={openHandler}
      onClose={openHandler}
      renderInput={(params) => {
        const placeholder = selected.length ? '' : 'Select Variety Type';
        return <TextField aria-label={'Choose variety'} placeholder={placeholder} {...params} />;
      }}
      onChange={(event, selected, reason, details) => {
        const value = selected
        .filter(({ label }) => details?.option.label === label)
        .map(({label}) => label);

        setFieldValue(name, value);
      }}
    />
  );
};
