import React from 'react';

import Button from '@mui/base/ButtonUnstyled';

import { CustomButtonComponent } from './style';

interface SharedButtonInterface {
  title: string;
  link: string;
}

type Props = SharedButtonInterface;

export const LinkButton: React.FC<Props> = ({ title, link }) => {
  return (
    <Button component={CustomButtonComponent} to={link}>
      {title}
    </Button>
  );
};
