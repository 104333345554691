import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { ColorVar } from '../../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => ({
  active: {
    '& .MuiChip-root': {
      backgroundColor: ColorVar.darkBlue,
      margin: '5px',
    },
    '& .MuiChip-root:hover': {
      backgroundColor: ColorVar.darkBlue,
    },
    '& .MuiChip-root:focus': {
      backgroundColor: ColorVar.darkBlue,
    },
    '& .MuiChip-label': {
      color: ColorVar.white,
      fontFamily: ['Roboto', 'sans-serif'].join(','),
      fontSize: '18px',
      lineHeight: '26px',
      fontWeight: 400,
      [theme.breakpoints.down('tablet')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
      },
    },
  },
  disabled: {
    '& .MuiChip-root': {
      backgroundColor: 'transparent',
      margin: '5px',
    },
    '& .MuiChip-root:hover': {
      backgroundColor: ColorVar.darkBlue,
    },
    '& .MuiChip-label': {
      color: ColorVar.black,
      fontFamily: ['Roboto', 'sans-serif'].join(','),
      fontSize: '18px',
      lineHeight: '26px',
      fontWeight: 400,
      [theme.breakpoints.down('tablet')]: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
      },
    },
  },
}));
