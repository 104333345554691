import React, { FC } from 'react';
import { Container, Typography, Button, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useMediaHook } from '../../../../../shared/hooks/useMediaHook';

import { useStyles } from './style';

interface IProps {
  closeMenuHandler?: () => void;
  touched: boolean;
}

export const FilterBarHeader: FC<IProps> = ({ touched, closeMenuHandler }) => {
  const { isMobile } = useMediaHook();
  const classes = useStyles();
  return (
    <Container aria-label='Select' sx={{ display: 'flex',padding: '0px 20px' }} component={'section'} className={classes.wrapper}>
      <Typography aria-level={1} component={'h1'} variant={'h3'}>
        Select
      </Typography>
      <Box component={'section'}>
        {touched && (
          <Button type={'reset'} color='inherit' variant={'outlined'}>
            Clear
          </Button>
        )}

        {isMobile && (
          <IconButton aria-label='Close filter' onClick={closeMenuHandler}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </Container>
  );
};
