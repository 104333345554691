import React from 'react';
import ButtonUnstyled, { ButtonUnstyledProps } from '@mui/base/ButtonUnstyled';

import { CustomButtonComponent } from './style';

interface SharedButtonInterface {
  title: string;
  type?: string;
}
type Props = SharedButtonInterface & ButtonUnstyledProps;

export const CommonButton: React.FC<Props> = ({ title, type, ...props }) => (
  <ButtonUnstyled role={'button'} {...props} type={type} component={CustomButtonComponent}>
    {title}
  </ButtonUnstyled>
);
