import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Container, Link as MaterialLink } from '@mui/material';
import { useDispatch } from 'react-redux';

import { AgreementModalComponent } from '../agreement-modal-component';
import { useMediaHook } from '../../hooks/useMediaHook';

import { useStyles } from './style';
import { setTermsAction } from '../../../store/application/reducer';

function FooterComponent() {
  const classes = useStyles();
  const [isPolicyModalVisible, showPolicyModal] = useState<boolean>(false);
  const { isMobile } = useMediaHook();
  const dispatch = useDispatch();

  const onTermsModalClick = () => {
    dispatch(setTermsAction(true));
  };
  const onPolicyModalClick = () => showPolicyModal(!isPolicyModalVisible);

  return (
    <>
      <Container component={'section'} sx={{ display: 'flex' }} className={classes.footerWrap}>
        <Link to={'/'}>
          <img
            alt={'University of Manitoba logo'}
            src={'./images/icons/logoIcon.svg'}
            width={isMobile ? 99 : 122}
            height={isMobile ? 47 : 59}
          />
        </Link>
        <Typography variant={'body1'} component={'p'}>
          © 2021 University of Manitoba
        </Typography>
        <Box component={'section'} className={classes.linkWrap}>
          <MaterialLink
            sx={{ marginRight: '40px' }}
            component='button'
            variant='body2'
            underline='always'
            onClick={onPolicyModalClick}
          >
            Privacy policy
          </MaterialLink>
          <MaterialLink component='button' variant='body2' underline='always' onClick={onTermsModalClick}>
            Terms of use
          </MaterialLink>
        </Box>
      </Container>
      <AgreementModalComponent
        open={isPolicyModalVisible}
        onClose={onPolicyModalClick}
        title={'Privacy policy'}
        description={`
          Welcome to the Fusarium Head Blight (FHB) interactive tool.
          This tool provides information on the risk of FHB,
          Fusarium Damaged Kernek (FDK) and Deoxynialenol (DON) across the
          agricultural region of the Canadian Prairies.
          To use this tool, select your province to read
          updates from the provincial specialist.
          Efforts have been make to ensure accuracy of the
          information provided on this tool.
          However, models do not have 100% accuracy so you are encouraged
          to conduct field observations to verify
          the information provided on this tool.
        `}
      />
    </>
  );
}

export default FooterComponent;
