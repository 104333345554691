import React, { Dispatch, RefObject, SetStateAction, useRef } from 'react'

import { MapContainer, TileLayer, GeoJSON, ScaleControl, FeatureGroup } from 'react-leaflet'
import Control from 'react-leaflet-custom-control'
import { EditControl } from 'react-leaflet-draw'

import { Button, Container, Menu, MenuItem, Typography } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { useNavigate } from 'react-router-dom'

import { Legend } from '../legend-component'
import { useStyles } from './style'
import { PopoverWrapper } from '../../../../../shared/components/popover-wrapper/popover-wrapper'
import { useDispatch, useSelector } from 'react-redux'
import { setPopoverStepAction } from '../../../../../store/application/reducer'
import { useMediaHook } from '../../../../../shared/hooks/useMediaHook'
import { heatmapDataSelector } from '../../../../../store/heatmap/selectors'
import { ScreenComponent } from '../screen-component'
import { provincesList } from '../../../WeeklyReport'

interface IProps {
  legend?: boolean
  controls?: boolean
  zoom?: boolean
  dragging?: boolean
  keyboard?: boolean
  setScreenUrl?: Dispatch<SetStateAction<string>>
}

export const ReactMap: React.FC<IProps> = ({
  legend,
  setScreenUrl,
  controls = true,
  zoom = true,
  dragging = true,
  keyboard = true,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const data = useSelector(heatmapDataSelector)
  const featureGroupRef = useRef<any>(null)
  const { isMobile } = useMediaHook()
  const openPopover = () => {
    dispatch(setPopoverStepAction(1))
  }

  const [anchorMenuWeeklyReport, setAnchorMenuWeeklyReport] = React.useState<null | HTMLElement>(null)
  const handleOpenWeeklyReportMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenuWeeklyReport(event.currentTarget)
  }
  const handleCloseWeeklyReportMenu = () => {
    setAnchorMenuWeeklyReport(null)
  }

  const onClickWeeklyReport = (provinceIndex: number) => {
    handleCloseWeeklyReportMenu()
    navigate('/map/weekly-report', { state: { provinceIndex }} )
  }
  const onClickExport = () => navigate('/map/export-report')

  return (
    <Container component={'section'} className={classes.wrapper} disableGutters={true}>
      <MapContainer
        className={'mapContainer'}
        dragging={dragging}
        doubleClickZoom={zoom}
        trackResize={zoom}
        touchZoom={zoom}
        scrollWheelZoom={zoom}
        keyboard={keyboard}
        center={[50.089371390397, -104.784531135578]}
        zoom={5}
        maxZoom={11}
        minZoom={6}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        {data ? (
          <GeoJSON
            data={data}
            style={(feature) => {
              let color = 'green'

              if (feature) {
                if (feature.properties.risk === 1) {
                  color = 'yellow'
                } else if (feature.properties.risk === 2) {
                  color = 'orange'
                } else if (feature.properties.risk === 3) {
                  color = 'red'
                }
              }

              return {
                color,
                weight: 0,
                fillOpacity: 0.4,
              }
            }}
          />
        ) : null}
        {!isMobile && controls && (
          <Control style={{ border: 'none', display: 'flex', flexDirection: 'row' }} position='topright'>
            <Button className={'weeklyReport'} onClick={handleOpenWeeklyReportMenu}>
              <img className={'reportImg'} alt={'Report icon'} src={'/images/icons/report.svg'} />
              <Typography variant={'subtitle1'} component={'p'}>
                Weekly Report
              </Typography>
            </Button>
            <Menu
              anchorEl={anchorMenuWeeklyReport}
              open={!!anchorMenuWeeklyReport}
              onClose={handleCloseWeeklyReportMenu}
              sx={{top: 3}}
            >
              {provincesList.map((province, index) => {
                if (!index) return null
                return (
                    <MenuItem
                        sx={{fontSize: 18, width: 168}}
                        key={index}
                        onClick={() => {
                          onClickWeeklyReport(index)
                        }}
                    >
                      {provincesList[index]}
                    </MenuItem>
                )
              })}
            </Menu>

            <PopoverWrapper step={6} media={['desktop']}>
              <Button
                className='exportBtn'
                disabled={!data}
                onClick={onClickExport}
                ref={React.createRef() as RefObject<HTMLButtonElement>}
              >
                <img className={'exportImg'} alt={'Export icon'} src={'/images/icons/export.svg'} />
                <Typography variant={'subtitle1'} component={'p'}>
                  Export
                </Typography>
              </Button>
            </PopoverWrapper>
          </Control>
        )}
        {isMobile && controls && (
          <Control style={{ border: 'none' }} position={'topright'}>
            <Button onClick={openPopover} aria-label='Learn how to use the map'>
              <HelpOutlineIcon />
            </Button>
          </Control>
        )}
        <FeatureGroup ref={featureGroupRef}>
          <EditControl
            position='topright'
            onDrawStart={() => {
              featureGroupRef.current?.clearLayers()
            }}
            onCreated={(e: any) => {
              const { lat, lng } = e.layer.getBounds().getCenter()
              console.log('[circle]:', {
                lat,
                lng,
                radius: e.layer.getRadius(),
              })
            }}
            draw={{
              circlemarker: false,
              marker: false,
              polygon: false,
              polyline: false,
              rectangle: false,
              circle: { metric: true },
            }}
          />
        </FeatureGroup>
        <ScaleControl />
        {setScreenUrl && <ScreenComponent setScreenUrl={setScreenUrl} />}
        {legend && <Legend />}
      </MapContainer>
    </Container>
  )
}
