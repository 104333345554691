import React from 'react';
import { FormControl, MenuItem, SelectProps, Select } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import { useMediaHook } from '../../../../shared/hooks/useMediaHook';

import { menuItemStylesDesktop, menuItemStylesMobile, useStyles } from './styles';

interface SelectorOwnProps {
  year: string;
}

const years = (back: number) => {
  const year = new Date().getFullYear();
  return Array.from({ length: back }, (v, i) => year - back + i + 1).reverse();
};
const yearsList = years(1);

type Props = SelectorOwnProps & SelectProps;

export const YearSelector: React.FC<Props> = ({ year, ...selectProps }) => {
  const classes = useStyles();
  const { isMobile } = useMediaHook();

  return (
    <FormControl fullWidth className={classes.formControl}>
      <Select
        area-label={'Choose year'}
        renderValue={() => (year ? year : 'Year')}
        displayEmpty
        id='year-select'
        value={year}
        IconComponent={KeyboardArrowDownRoundedIcon}
        {...selectProps}
      >
        {yearsList.map((item, index) => (
          <MenuItem key={index} value={item} sx={isMobile ? menuItemStylesMobile : menuItemStylesDesktop}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
