import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { rootStateSelector } from '../store';

export const weeklyReportsSelector = createDraftSafeSelector(
  rootStateSelector,
  (state) => state.weeklyReports
)

export const weeklyReportsDataSelector = createDraftSafeSelector(
  weeklyReportsSelector,
  (state) => state.ids.map(id => state.entities[id])
)
