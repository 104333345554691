import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { ColorVar } from '../../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    '& .MuiBackdrop-root': {
      backgroundColor: ColorVar.transparentBackdrop,
    },
  },
  modalWrapper: {
    borderRadius: '10px',
    backgroundColor: ColorVar.white,
    padding: '20px',
    '& .MuiTypography-h2': {
      marginBottom: '27px',
    },
    '& .MuiTypography-body2': {
      marginBottom: '27px',
    },
    [theme.breakpoints.between('tablet', 'desktop')]: {
      padding: '25px',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '30px',
    },
    [theme.breakpoints.between(0, 'tablet')]: {
      width: '100%',
    },
  },
  acceptBtn: {
    marginLeft: '12px',
  },
}));

export const modalContentStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};
