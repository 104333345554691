import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { ColorVar } from '../../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    padding: '10px 0 10px 0',
  },
  accordionSummary: {
    '&.MuiAccordionSummary-root': {
      padding: 0,
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      backgroundColor: ColorVar.orange,
      borderRadius: 100,
    },
  },
  accordionDetails: {
    '&.MuiAccordionDetails-root': {
      padding: '0 80px 30px 0',
      [theme.breakpoints.down('tablet')]: {
        padding: '0 40px 30px 0',
      },
    },
    padding: '0px 0 20px 0',
  },
  titleWrapper: {
    display: 'flex',
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('tablet')]: {
      alignItems: 'center',
    },
  },
  subtitle: {
    color: ColorVar.darkGrey,
    [theme.breakpoints.down('tablet')]: {
      paddingTop: '10px',
    },
    [theme.breakpoints.up('tablet')]: {
      paddingLeft: '10px',
    },
  },
  details: {
    color: ColorVar.darkGrey,
    marginLeft: 10,
    '&.MuiTypography-root': {
      fontSize: 18,
    },
  },
}));
