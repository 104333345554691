import { makeStyles } from '@mui/styles';
import { ColorVar } from '../../../../../styles/variables';

export const useStyles = makeStyles({
  wrapper: {
    '& .MuiOutlinedInput-root': {
      fontSize: '16px',
      lineHeight: '24px',
      height: '44px',
      width: '100%',
      borderRadius: '10px',
    },
  },
  mobileDialogClasses: {
    '& .PrivatePickersToolbar-root ': {
      backgroundColor: ColorVar.darkBlue,
      color: ColorVar.white,
    },
    '& .MuiTypography-root': {
      color: ColorVar.white,
    },
    '& .MuiTypography-h4': {
      fontSize: '34px',
      lineHeight: '36px',
    },
    '& .MuiTypography-overline': {
      fontSize: '10px',
      lineHeight: '16px',
      paddingTop: '5px',
      paddingBottom: '15px',
    },
    '& .MuiTypography-caption': {
      color: ColorVar.datePickerGray,
    },
    '& .MuiDialog-paper': {
      color: ColorVar.datePickerGray,
    },
    '& .PrivatePickersSlideTransition-root': {
      minHeight: 'auto',
    },
    '& .MuiButton-text': {
      fontSize: '14px',
      lineHeight: '16px',
      color: ColorVar.darkBlue,
    },
    '& .css-1pydtip': {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 500,
    },
  },
  desktopDialogClasses: {
    width: '100%',
    marginLeft: '18px',
    marginTop: '-50px',

    '& .PrivatePickersSlideTransition-root': {
      minHeight: '234px',
    },

    '& .css-1pydtip': {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 500,
      color: ColorVar.datePickerGray,
    },

    '& button.MuiPickersDay-root.Mui-selected': {
      backgroundColor: ColorVar.black,
    },
    '& button.MuiPickersDay-root.MuiPickersDay-today': {
      borderColor: ColorVar.black,
    },

    '& .Mui-focusVisible': {
      boxShadow: '0 2px 20px 0 rgba(61, 71, 82, 0.1),0 0 0 2.5px rgba(0, 127, 255, 0.5)',
    },
  },
});
