import React, { FC, RefObject } from 'react';
import ReactDOMServer from "react-dom/server";
import classNames from 'classnames';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import JsPDF from "jspdf";
import { exportComponentAsJPEG } from 'react-component-export-image';

import { CommonIcon } from '../../../../shared/components/common-icon';
import { PdfComponent } from '../pdf-component';

import { useStyles } from './styles';
import { ColorVar } from '../../../../styles/variables';

interface IProps {
  screenUrl: string,
  pageElement: RefObject<HTMLElement>,
  detailsData: Array<{
    icon: string,
    alt: string,
    title: string,
    detail: () => string | undefined,
  }>
}

export const DownloadComponent: FC<IProps> = ({ screenUrl, pageElement, detailsData }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const DownloadIcon = <CommonIcon alt='Download icon' src='/images/icons/download.svg' />;
  const DownloadIconGrey = <CommonIcon alt='Download icon' src='/images/icons/downloadGrey.svg' />;
  const PrintIcon = <CommonIcon alt='Print icon' src='/images/icons/print.svg' />;

  const onClickPrintHandler = () => window.print();

  const onClickSaveAsJPEGHandler = async () => {
    await exportComponentAsJPEG(pageElement, {
      fileName: 'export-report'
    });
    handleClose();
  };

  const onClickSaveAsPDFHandler = async () => {
    const doc = new JsPDF("p", "px", "a4");
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();

    doc.html(ReactDOMServer.renderToStaticMarkup(<PdfComponent
      width={width}
      height={height}
      screenUrl={screenUrl}
      detailsData={detailsData}
    />), {
      callback: () => {
        doc.save("export-report.pdf");
      }
    });

    handleClose();
  };

  return <Box component={'section'} className={classes.box}>
    <Button
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      style={{
        color: open ? ColorVar.darkBlue : ColorVar.black,
      }}
      onClick={handleClick}
      className={classes.button}
      startIcon={open ? DownloadIcon : DownloadIconGrey}
    >
      Download
    </Button>
    <Menu
      elevation={0}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      className={classes.downloadList}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem className={classes.downloadMenuItem} disabled={!screenUrl} onClick={onClickSaveAsPDFHandler}>
        Save as PDF
      </MenuItem>
      <MenuItem className={classes.downloadMenuItem} onClick={onClickSaveAsJPEGHandler}>Save as JPEG</MenuItem>
    </Menu>
    <Button
      className={classNames(classes.button, classes.print)}
      startIcon={PrintIcon}
      onClick={onClickPrintHandler}
    >
      Print
    </Button>
  </Box>;
}
