import React from 'react';

import { Box, Typography } from '@mui/material';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

import { useStyles } from './style';

interface IProps {
  errorMes: string;
}

export const CommonError = ({ errorMes }: IProps) => {
  const classes = useStyles();
  return (
    <Box component={'section'} className={classes.wrapper}>
      <ErrorOutlineRoundedIcon />
      <Typography component={'p'} variant={'body2'}>
        {errorMes}
      </Typography>
    </Box>
  );
};
