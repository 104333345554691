import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  formControl: {
    maxWidth: '90px',

    '& .MuiOutlinedInput-root': {
      height: '44px',
      width: '112px',
      borderRadius: '10px',
    },
    '& .MuiInputBase-root': {
      fontSize: '16px',
      lineHeight: '24px',
      padding: '0px',
      margin: '0px',
    },
  },
});

export const menuItemStylesMobile = {
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 400,
  padding: 0,
  margin: 0,
  display: 'flex',
  justifyContent: 'center',
};

export const menuItemStylesDesktop = {
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: 400,
  padding: 0,
  margin: 0,
  display: 'flex',
  justifyContent: 'center',
};
