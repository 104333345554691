export const varietyTypeOptions = [
  { cropType: 'barley', label: 'AAC Connect', value: 'Resistant' },
  { cropType: 'barley', label: 'AAC Synergy', value: 'Intermediate' },
  { cropType: 'barley', label: 'AC Metcalfe', value: 'Intermediate' },
  { cropType: 'barley', label: 'CDC Bow', value: 'Susceptible' },
  { cropType: 'barley', label: 'CDC Copeland', value: 'Intermediate' },
  { cropType: 'barley', label: 'CDC Fraser', value: 'Intermediate' },
  { cropType: 'barley', label: 'AAC Goldman', value: 'Resistant' },
  { cropType: 'barley', label: 'AB BrewNet', value: 'Resistant' },
  { cropType: 'barley', label: 'Bentley', value: 'Intermediate' },
  { cropType: 'barley', label: 'CDC Churchill', value: 'Susceptible' },
  { cropType: 'barley', label: 'CDC Copper', value: 'Susceptible' },
  { cropType: 'barley', label: 'CDC Goldstar', value: 'Susceptible' },
  { cropType: 'barley', label: 'CDC Kindersley', value: 'Intermediate' },
  { cropType: 'barley', label: 'CDC Meredith', value: 'Intermediate' },
  { cropType: 'barley', label: 'CDC PlatinumStar', value: 'Resistant' },
  { cropType: 'barley', label: 'CDC Polarstar', value: 'Resistant' },
  { cropType: 'barley', label: 'Celebration', value: 'Susceptible' },
  { cropType: 'barley', label: 'Lacey', value: 'Susceptible' },
  { cropType: 'barley', label: 'LEGACY', value: 'Susceptible' },
  { cropType: 'barley', label: 'Lowe', value: 'Resistant' },
  { cropType: 'barley', label: 'Merit 57', value: 'Susceptible' },
  { cropType: 'barley', label: 'Newdale', value: 'Intermediate' },
  { cropType: 'barley', label: 'Stellar-ND', value: 'Intermediate' },
  { cropType: 'barley', label: 'Tradition', value: 'Susceptible' },
  { cropType: 'barley', label: 'AB Advantage', value: 'Susceptible' },
  { cropType: 'barley', label: 'AB Cattlelac', value: 'Susceptible' },
  { cropType: 'barley', label: 'AB Tofield', value: 'Susceptible' },
  { cropType: 'barley', label: 'AB Wrangler', value: 'Resistant' },
  { cropType: 'barley', label: 'AC Ranger', value: 'Susceptible' },
  { cropType: 'barley', label: 'Amisk', value: 'Susceptible' },
  { cropType: 'barley', label: 'Brahma', value: 'Intermediate' },
  { cropType: 'barley', label: 'Breton', value: 'Susceptible' },
  { cropType: 'barley', label: 'CDC Austenson', value: 'Intermediate' },
  { cropType: 'barley', label: 'CDC Coalition', value: 'Intermediate' },
  { cropType: 'barley', label: 'CDC Cowboy', value: 'Resistant' },
  { cropType: 'barley', label: 'CDC Maverick', value: 'Resistant' },
  { cropType: 'barley', label: 'CDC YORKTON', value: 'Susceptible' },
  { cropType: 'barley', label: 'CDC Trey', value: 'Intermediate' },
  { cropType: 'barley', label: 'Canmore', value: 'Intermediate' },
  { cropType: 'barley', label: 'Gadsby', value: 'Intermediate' },
  { cropType: 'barley', label: 'Seebe', value: 'Resistant' },
  { cropType: 'barley', label: 'Chigwell', value: 'Susceptible' },
  { cropType: 'barley', label: 'Champion', value: 'Intermediate' },
  { cropType: 'barley', label: 'Claymore', value: 'Resistant' },
  { cropType: 'barley', label: 'CONLON', value: 'Resistant' },
  { cropType: 'barley', label: 'Desperado', value: 'Susceptible' },
  { cropType: 'barley', label: 'Oreana', value: 'Susceptible' },
  { cropType: 'barley', label: 'CDC Ascent', value: 'Resistant' },
  { cropType: 'barley', label: 'CDC Clear', value: 'Resistant' },
  { cropType: 'barley', label: 'TR17635', value: 'Resistant' },
  { cropType: 'barley', label: 'FB209', value: 'Resistant' },
  { cropType: 'barley', label: 'SR17519', value: 'Susceptible' },
  { cropType: 'barley', label: 'SR18524', value: 'Susceptible' },
  { cropType: 'barley', label: 'TR16742', value: 'Intermediate' },
  { cropType: 'barley', label: 'TR18645', value: 'Intermediate' },
  { cropType: 'barley', label: 'TR18647', value: 'Resistant' },

  { cropType: 'durum', label: 'AAC Cabri', value: 'Susceptible' },
  { cropType: 'durum', label: 'AAC Congress', value: 'Susceptible' },
  { cropType: 'durum', label: 'AAC Current', value: 'Susceptible' },
  { cropType: 'durum', label: 'AAC Durafield', value: 'Susceptible' },
  { cropType: 'durum', label: 'AAC Grainland', value: 'Susceptible' },
  { cropType: 'durum', label: 'AAC Marchwell', value: 'Susceptible' },
  { cropType: 'durum', label: 'AAC Raymore', value: 'Susceptible' },
  { cropType: 'durum', label: 'AAC Spitfire', value: 'Susceptible' },
  { cropType: 'durum', label: 'AAC Stronghold', value: 'Susceptible' },
  { cropType: 'durum', label: 'AAC Succeed', value: 'Susceptible' },
  { cropType: 'durum', label: 'AC Navigator', value: 'Susceptible' },
  { cropType: 'durum', label: 'Brigade', value: 'Susceptible' },
  { cropType: 'durum', label: 'CDC Alloy', value: 'Susceptible' },
  { cropType: 'durum', label: 'CDC Carbide', value: 'Susceptible' },
  { cropType: 'durum', label: 'CDC Credence', value: 'Susceptible' },
  { cropType: 'durum', label: 'CDC Flare', value: 'Susceptible' },
  { cropType: 'durum', label: 'CDC Desire', value: 'Susceptible' },
  { cropType: 'durum', label: 'CDC Dynamic', value: 'Susceptible' },
  { cropType: 'durum', label: 'CDC Fortitude', value: 'Susceptible' },
  { cropType: 'durum', label: 'CDC Precision', value: 'Susceptible' },
  { cropType: 'durum', label: 'CDC Verona', value: 'Susceptible' },
  { cropType: 'durum', label: 'CDC Vivid', value: 'Susceptible' },
  { cropType: 'durum', label: 'Enterprise', value: 'Susceptible' },
  { cropType: 'durum', label: 'Eurostar', value: 'Susceptible' },
  { cropType: 'durum', label: 'Strongfield', value: 'Susceptible' },
  { cropType: 'durum', label: 'Transcend', value: 'Susceptible' },

  { cropType: 'springWheat', label: '5604HR', value: 'Intermediate' },
  { cropType: 'springWheat', label: '5605HR', value: 'Resistant' },
  { cropType: 'springWheat', label: 'AAC Alida', value: 'Resistant' },
  { cropType: 'springWheat', label: 'AAC Brandon', value: 'Resistant' },
  { cropType: 'springWheat', label: 'AAC Broadacres', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Cameron', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Connery', value: 'Resistant' },
  { cropType: 'springWheat', label: 'AAC Elie', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Hodge', value: 'Resistant' },
  { cropType: 'springWheat', label: 'AAC Jatharia', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC LeRoy', value: 'Resistant' },
  { cropType: 'springWheat', label: 'AAC Magnet', value: 'Resistant' },
  { cropType: 'springWheat', label: 'AAC Prevail', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Redberry', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Redstar', value: 'Resistant' },
  { cropType: 'springWheat', label: 'AAC Redwater', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Russell', value: 'Resistant' },
  { cropType: 'springWheat', label: 'AAC Starbuck', value: 'Resistant' },
  { cropType: 'springWheat', label: 'AAC Tisdale', value: 'Resistant' },
  { cropType: 'springWheat', label: 'AAC Viewfield', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC W1876', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Warman', value: 'Resistant' },
  { cropType: 'springWheat', label: 'AAC Wheatland', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AC Domain', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'Bolles', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Carberry', value: 'Resistant' },
  { cropType: 'springWheat', label: 'Cardale', value: 'Resistant' },
  { cropType: 'springWheat', label: 'CDC Abound', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'CDC Adamant', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'CDC Bradwell', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'CDC Go', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'CDC Hughes', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'CDC Landmark', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'CDC Ortona', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'CDC Plentiful', value: 'Resistant' },
  { cropType: 'springWheat', label: 'CDC SKRush', value: 'Resistant' },
  { cropType: 'springWheat', label: 'CDC Stanley', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'CDC Titanium', value: 'Resistant' },
  { cropType: 'springWheat', label: 'CDC Utmost', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'Daybreak', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Ellerslie', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Glenn', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Go Early', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Goodeve', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'Jake', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'Muchmore', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'Parata', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Rednet', value: 'Resistant' },
  { cropType: 'springWheat', label: 'ShawVB', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'Superb', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'SY Brawn', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'SY Chert', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'SY Gabbro', value: 'Resistant' },
  { cropType: 'springWheat', label: 'SY Natron', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'SY Obsidian', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'SY Slate', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'SY Sovite', value: 'Resistant' },
  { cropType: 'springWheat', label: 'SY Torach', value: 'Resistant' },
  { cropType: 'springWheat', label: 'Thorsby', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Tracker', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Vesper', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'WR859', value: 'Resistant' },
  { cropType: 'springWheat', label: 'AAC Crossfield', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Crusader', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Entice', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Foray', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Goodwin', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Penhold', value: 'Resistant' },
  { cropType: 'springWheat', label: 'AAC Ryley', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'AAC Tenacious', value: 'Resistant' },
  { cropType: 'springWheat', label: 'Accelerate', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'CDC Reign', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'CDC Terrain', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'SY Rowyn', value: 'Resistant' },
  { cropType: 'springWheat', label: 'AAC Concord', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'AAC Tradition', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AC Foremost', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'Elgin', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Faller', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Harvest', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'KANE', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Prosper', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Unity', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Awesome', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Innova', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'AAC NRG097', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Proclaim', value: 'Resistant' },
  { cropType: 'springWheat', label: 'CDC Throttle', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Pasteur', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'SY087', value: 'Resistant' },
  { cropType: 'springWheat', label: 'WPB Whistler', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Cirrus', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Iceberg', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Whitefox', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Snowstar', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'Whitehawk', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Snowbird', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'AAC Chiffon', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'AAC Indus', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'AAC Paramount', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'AC Andrew', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Sadash', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'CDN Bison', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'Glencross VB', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'BW1093', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'BW5031', value: 'Susceptible' },
  { cropType: 'springWheat', label: 'BW5044', value: 'Resistant' },
  { cropType: 'springWheat', label: 'BW5045', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'BW5055', value: 'Resistant' },
  { cropType: 'springWheat', label: 'BW5072', value: 'Resistant' },
  { cropType: 'springWheat', label: 'CS11200104-11', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'CS11200214-17', value: 'Resistant' },
  { cropType: 'springWheat', label: 'HY2068', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'LNR15-1741', value: 'Intermediate' },
  { cropType: 'springWheat', label: 'PT598', value: 'Intermediate' },

  { cropType: 'winterWheat', label: 'AAC Elevate', value: 'Intermediate' },
  { cropType: 'winterWheat', label: 'AAC Gateway', value: 'Intermediate' },
  { cropType: 'winterWheat', label: 'AAC Goldrush', value: 'Intermediate' },
  { cropType: 'winterWheat', label: 'AAC Network', value: 'Intermediate' },
  { cropType: 'winterWheat', label: 'AAC Wildfire', value: 'Resistant' },
  { cropType: 'winterWheat', label: 'CDC Buteo', value: 'Resistant' },
  { cropType: 'winterWheat', label: 'CDC Chase', value: 'Susceptible' },
  { cropType: 'winterWheat', label: 'Emerson', value: 'Resistant' },
  { cropType: 'winterWheat', label: 'Flourish', value: 'Susceptible' },
  { cropType: 'winterWheat', label: 'McClintock', value: 'Susceptible' },
  { cropType: 'winterWheat', label: 'Moats', value: 'Susceptible' },
  { cropType: 'winterWheat', label: 'Broadview', value: 'Susceptible' },
  { cropType: 'winterWheat', label: 'CDC Falcon', value: 'Susceptible' },
  { cropType: 'winterWheat', label: 'Peregrine', value: 'Intermediate' },
  { cropType: 'winterWheat', label: 'AAC Icefield', value: 'Intermediate' },
  { cropType: 'winterWheat', label: 'W520', value: 'Intermediate' },
  { cropType: 'winterWheat', label: 'W522', value: 'Intermediate' },
  { cropType: 'winterWheat', label: 'W563', value: 'Resistant' },
];
