import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { ColorVar } from '../../../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => ({
  filterBarWrap: {
    height: '100%',
    '& .form': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiList-root': {
      overflowY: 'visible',
      margin: '0px 20px',

      '& .MuiListItem-root': {
        padding: '0px 0px 4px',
      },
      '& .MuiAccordion-root': {
        width: '100%',
        boxShadow: 'unset',
        borderBottom: '1px solid #F1F1F1',

        '& .MuiAccordionDetails-root': {
          padding: '0px',
          paddingBottom: '10px',
          marginLeft: '5px',
          [theme.breakpoints.up('tablet')]: {
            marginLeft: 0,
          },
        },
      },
      '& 	.MuiAccordionSummary-root': {
        padding: 0,
      },
      '& 	.MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    '& .MuiListItem-root:nth-child(2)': {
      '& img': {
        marginRight: '15px',
      },
    },
    '& .howItWorksButton': {
      margin: '20px 20px 0px',
      width: '334px',
      height: '64px',
      borderColor: ColorVar.mediumLightGrey,
      borderWidth: '2px',
      borderRadius: '5px',
      padding: '12px 15px',
      justifyContent: 'flex-start',
      textTransform: 'none',
      fontSize: '18px',
      lineHeight: '21px',
      color: ColorVar.black,
      fontWeight: 500,
    },
  },
  helpIcon: {
    color: ColorVar.orange2,
    marginRight: '8px',
  },
  arrowIcon: {
    width: '9px',
    fontWeight: 400,
    marginLeft: '28px',
  },

  accordHeaderWrap: {
    height: '66px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& p': {
      fontWeight: 500,
      [theme.breakpoints.between(0, 'tablet')]: {
        fontSize: '16px',
        lineHeight: '18.75px',
      },
      [theme.breakpoints.up('tablet')]: {
        fontSize: '18px',
        lineHeight: '21.09px',
      },
    },
  },
  accordIcon: {
    marginRight: '10px',
  },
  expandIcon: {
    color: ColorVar.black,
    backgroundColor: ColorVar.orange,
    borderRadius: 100,
  },
  btnWrapper: {
    '& .ButtonUnstyled-root': {
      width: '332px',
    },
    height: '89px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: ColorVar.lightGrey,
  },
  formElements: {
    height: 'calc(100% - 89px)',
    overflow: 'auto',
  },
}));
