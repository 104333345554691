import React from 'react';
import { Outlet } from 'react-router-dom';

import { ToolbarComponent } from '../toolbar-component';
import { useStyles } from './styles';
import { TermsOfUse } from '../terms-of-use-component';

export const Layout: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <header>
        <ToolbarComponent />
      </header>
      <main className={classes.main}>
        <Outlet />
      </main>

      {/* Modals */}
      <TermsOfUse />
    </>
  );
};
