import React from 'react';

import Control from 'react-leaflet-custom-control';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import CircleIcon from '@mui/icons-material/Circle';

import { useStyles } from './style';

export const Legend: React.FC = () => {
  const classes = useStyles();
  return (
    <Control position={'bottomleft'} style={{ border: 'none' }}>
      <Accordion className={classes.legendWrap}>
        <AccordionSummary className={classes.legendHeading} expandIcon={<KeyboardArrowDownRoundedIcon />}>
          <Typography component={'h2'} variant='body2'>
            Risk Legend
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {LegendData.map((data) => (
              <ListItem key={data.id}>
                <ListItemIcon>{data.icon}</ListItemIcon>
                <ListItemText>{data.text}</ListItemText>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Control>
  );
};

const LegendData = [
  {
    id: 1,
    icon: <CircleIcon sx={{ color: '#D93637' }} />,
    text: 'High',
  },
  {
    id: 2,
    icon: <CircleIcon sx={{ color: '#F2A900' }} />,
    text: 'Moderate',
  },
  {
    id: 3,
    text: 'Low',
    icon: <CircleIcon sx={{ color: '#006F62' }} />,
  },
];
