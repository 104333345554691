import React, { useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'

import { FilterBar } from './HeatMap/filter-bar'
import { ReactMap } from './HeatMap/map-section/map-component'
import { DrawerFilterBar } from '../../shared/components/drawer-filter-bar'

import { useStyles } from './styles'
import { useMediaHook } from '../../shared/hooks/useMediaHook'
import { setPopoverStepAction, setTermsAction } from '../../store/application/reducer'

export const Map: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [legendActive, setLegendActive] = useState(false)
  const showLegend = (bool: boolean) => setLegendActive(bool)

  const { isMobile } = useMediaHook()

  useEffect(() => {
    if (!localStorage.getItem('isShownTerms')) {
      dispatch(setTermsAction(true))
    }

    if (!localStorage.getItem('isShownPopover')) {
      dispatch(setPopoverStepAction(1))
    }
  }, [])

  return (
    <>
      {isMobile && (
        <>
          <ReactMap legend={legendActive} />
          <DrawerFilterBar showLegend={() => showLegend(true)} />
        </>
      )}
      {!isMobile && (
        <Box component={'section'} className={classes.container}>
          <FilterBar width={'auto'} showLegend={() => showLegend(true)} />
          <ReactMap legend={legendActive} />
        </Box>
      )}
      <Outlet />
    </>
  )
}
