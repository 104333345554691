import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GeoJsonObject } from 'geojson'
import { varietyTypeOptions } from '../../routes/Map/HeatMap/filter-bar/varietyType'

export type HeatmapResponse = {
  id: number
  cropType: string
  riskType: string
  date: string
  data: GeoJsonObject
}
export interface HeatmapRequest {
  date: Date | string
  cropType: string
  varietyType: string[]
  risk: string
}

export interface WeeklyReportsResponse {
  id: number
  date: Date
  province: string
  report: string
}
export interface WeeklyReportsRequest {
  province?: string
  year: string
}

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    paramsSerializer: (params) => {
      return Object.keys(params)
        .map((key) => {
          const value = params[key]

          if (Array.isArray(value)) {
            return value.map((el, i) => `${key}[]=${params[key][i]}`).join('&')
          }

          return `${key}=${params[key]}`
        })
        .join('&')
    }
  }),
  endpoints: (builder) => ({
    fetchHeatmap: builder.mutation<HeatmapResponse, HeatmapRequest>({
      query: (credentials) => {
        const varietyType = varietyTypeOptions.find(({ label }) => label === credentials.varietyType[0])

        return {
          url: 'heatmap',
          method: 'GET',
          params: {
            ...credentials,
            varietyType: varietyType?.value
          }
        }
      }
    }),
    fetchWeeklyReports: builder.mutation<WeeklyReportsResponse[], WeeklyReportsRequest>({
      query: (credentials) => ({
        url: 'weekly-reports',
        method: 'GET',
        params: credentials
      })
    })
  })
})

export const { useFetchHeatmapMutation, useFetchWeeklyReportsMutation } = api
