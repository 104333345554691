import { makeStyles } from '@mui/styles';

import { ColorVar } from '../../../styles/variables';

export const useStyles = makeStyles(() => ({
  navWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    padding: '15px 14.5px 10px',
    justifyContent: 'space-between',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',

    '& > *': {
      width: '100%',
    },
  },
  underlinedLink: {
    position: 'relative',
    textDecoration: 'none',
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '45px',
    color: ColorVar.black,
    borderBottom: '2px solid  #F2A900',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginRight: '20px',
  },
  link: {
    position: 'relative',
    textDecoration: 'none',
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '45px',
    color: ColorVar.black,
    borderBottom: `2px solid  ${ColorVar.white}`,
    paddingLeft: '20px',
    paddingRight: '20px',
    marginRight: '20px',
    '&:hover': {
      borderBottom: `2px solid  ${ColorVar.borderGrey}`,
    },
  },
}));
