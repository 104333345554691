import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Box } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { useStyles } from './styles';
import { useMediaHook } from '../../hooks/useMediaHook';

export interface IAccordionData {
  title: string;
  details: string;
  subtitle?: string;
  capitalize?: boolean;
}

export const CommonAccordion: React.FC<IAccordionData> = ({ title, subtitle, details, capitalize }) => {
  const classes = useStyles();
  const { isMobile } = useMediaHook();

  const modifiedTitle = subtitle && !isMobile ? title + ' • ' : title;
  return (
    <Accordion disableGutters elevation={0} className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ fontSize: 26, color: 'black' }} />}
        className={classes.accordionSummary}
      >
        <Box component={'section'} className={classes.titleWrapper}>
          <Typography
            sx={{ textTransform: capitalize ? 'capitalize' : 'none' }}
            aria-level={2}
            variant={'h4'}
            component={'h4'}
          >
            {modifiedTitle}
          </Typography>
          {subtitle && (
            <Typography variant={'body2'} component={'p'} className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Typography
          variant={'body2'}
          component={'p'}
          className={classes.details}
          dangerouslySetInnerHTML={{ __html: details }}
        />
      </AccordionDetails>
    </Accordion>
  );
};
