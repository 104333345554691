import React, { RefObject } from 'react'
import { Button, Box } from '@mui/material'
import { useStyles } from './style'
import { PopoverWrapper } from '../../../../shared/components/popover-wrapper/popover-wrapper'
import { useDispatch, useSelector } from 'react-redux'
import { setSidebarShowAction } from '../../../../store/application/reducer'
import { useNavigate } from 'react-router-dom'
import { heatmapDataSelector } from '../../../../store/heatmap/selectors'

export const MobileButtonsBar: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const mapData = useSelector(heatmapDataSelector)

  const openMenuHandler = () => {
    dispatch(setSidebarShowAction(true))
  }

  const onClickExport = () => navigate('/map/export-report')

  return (
    <Box component={'section'} className={classes.wrapper}>
      <PopoverWrapper step={1} media={['mobile']}>
        <Button
          onClick={openMenuHandler}
          color={'inherit'}
          variant={'outlined'}
          ref={React.createRef() as RefObject<HTMLButtonElement>}
        >
          <img alt={'sliders icon'} src={'images/icons/slider.svg'} />
          Sort and Filter
        </Button>
      </PopoverWrapper>
      <PopoverWrapper step={6} media={['mobile']}>
        <Button
          disabled={!mapData}
          color={'inherit'}
          variant={'outlined'}
          ref={React.createRef() as RefObject<HTMLButtonElement>}
          onClick={onClickExport}
        >
          <img alt={'export icon'} src={'images/icons/export.svg'} />
          Export map
        </Button>
      </PopoverWrapper>
    </Box>
  )
}
