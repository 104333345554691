import React from 'react';
import { Typography, Container } from '@mui/material';

import { useStyles } from './styles';
import { CommonAccordion, IAccordionData } from '../../shared/components/common-accordion';

export const FAQ: React.FC = () => {
  const classes = useStyles();

  return (
    <Container component={'section'} className={classes.wrapper}>
      <Typography aria-level={1} variant='h3' component='h1'>
        Frequently Asked Questions
      </Typography>
      {data.map((item, index) => (
        <CommonAccordion capitalize={false} key={index} title={item.title} details={item.details} />
      ))}
    </Container>
  );
};

const data: IAccordionData[] = [
  {
    title: 'Who can use the FHB risk map?',
    details:
      'Anyone interested in knowing the FHB, FDK and DON risk levels in their area can use this map.  Using weather data across the Prairies, it provides a qualitative estimate of expected risk levels.  The risk level estimated for an area does not mean that every field in that area will experience such risk.  However, there is a good chance that the risk level indicated on the map for any crop on any specific day will match field observations.  Producers should conduct a field survey validation before making fungicide application decisions.',
  },
  {
    title: 'How do I use the FHB risk map?',
    details:
      'Steps on how the map works are provided on the homepage under “How it works” or by visiting the Map tab on the top right corner of the screen. ',
  },
  {
    title: 'How accurate is the FHB risk map data?',
    details:
      'The models were developed from plot studies established at 15 locations across the three Canadian Prairie provinces – 5 locations at each Province and each location had 40 plots over a 3-year period. The models were validated at about 300 producer fields across the Prairies with accuracies ranging from 70 to 100%. ',
  },
  {
    title: 'Which provinces are supported?',
    details: 'Alberta, Saskatchewan and Manitoba',
  },
  {
    title: 'Do I have to pay to use the FHB risk map?',
    details:
      'The viewer is free to use. Funding for the research was provided through the Integrated Crop Agronomy Cluster Program, part of the Canadian Agricultural Partnership. The list of funding partners and weather data providers is on the home page.',
  },
];
