import React from 'react';
import { Box, Typography } from '@mui/material';

import { useMediaHook } from '../../../shared/hooks/useMediaHook';
import { CommonImage } from '../../../shared/components/common-image-component';

import { useStyles } from './style';

export const PartnersSection: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useMediaHook();

  return (
    <>
      <Box component={'section'} className={classes.textWrap}>
        <Typography variant={'h2'} component={'h2'}>
          Funding partners
        </Typography>
        <Typography variant={'body1'} component={'p'}>
          This project is funded through the AgriScience Program as part of the Canadian Agricultural Partnership, a
          federal, provincial, territorial initiative.
        </Typography>
      </Box>
      <Box component={'section'} display='flex' className={classes.imagesContainer}>
        {partnersData.map((data, index) => {
          return (
            <Box key={index} component={'section'} className={classes.imagesWrapper}>
              <CommonImage
                alt={data.alt}
                src={data.src}
                width={isMobile ? data.mobileWidth : data.tabletWidth}
                height={isMobile ? data.mobileHeight : data.tabletHeight}
              />
            </Box>
          );
        })}
      </Box>
    </>
  );
};

const partnersData = [
  {
    src: '/images/partners/canArgiPartn.jpg',
    alt: 'Canadian Agricultural Partnership logo',
    mobileWidth: 106,
    mobileHeight: 51,
    tabletWidth: 170,
    tabletHeight: 82,
  },
  {
    src: '/images/partners/argiAndAgriFoodCan.jpg',
    alt: 'Agriculture and Agri-Food Canada logo',
    mobileWidth: 156,
    mobileHeight: 48,
    tabletWidth: 225,
    tabletHeight: 69,
  },
  {
    src: '/images/partners/integrCropAgrCluster.jpg',
    alt: 'Integrated Crop Agronomy Cluster logo',
    mobileWidth: 143,
    mobileHeight: 56,
    tabletWidth: 223,
    tabletHeight: 87,
  },
  {
    src: '/images/partners/brewMaltBarlResInst.jpg',
    alt: 'Brewing and Malting Barley Research Institute logo',
    mobileWidth: 134,
    mobileHeight: 74,
    tabletWidth: 211,
    tabletHeight: 118,
  },
  {
    src: '/images/partners/saskWheat.jpg',
    alt: 'Sask Wheat logo',
    mobileWidth: 150,
    mobileHeight: 56,
    tabletWidth: 229,
    tabletHeight: 91,
  },
  {
    src: '/images/partners/wgrf.jpg',
    alt: 'WGRF logo',
    mobileWidth: 139,
    mobileHeight: 54,
    tabletWidth: 230,
    tabletHeight: 88,
  },
  {
    src: '/images/partners/mca.jpg',
    alt: 'Manitoba Crop Alliance logo',
    mobileWidth: 130,
    mobileHeight: 55,
    tabletWidth: 229,
    tabletHeight: 98,
  },
  {
    src: '/images/partners/albertaWheat.jpg',
    alt: 'Alberta Wheat Commission logo',
    mobileWidth: 138,
    mobileHeight: 54,
    tabletWidth: 193,
    tabletHeight: 93,
  },
  {
    src: '/images/partners/pog.jpg',
    alt: 'Prairie Oat Growers Association logo',
    mobileWidth: 143,
    mobileHeight: 69,
    tabletWidth: 178,
    tabletHeight: 97,
  },
];
