export const routeConfig = [
  {
    path: '/',
    name: 'Home',
  },
  {
    path: '/map',
    name: 'Map',
    nestedRoutes: [
      { path: '/map/weekly-report', name: 'Weekly Report' },
      { path: '/map/export-report', name: 'Export Report' },
    ],
  },
  {
    path: '/map/weekly-report',
    name: 'Weekly Report',
  },
  {
    path: '/faq',
    name: 'FAQ',
  },
];
