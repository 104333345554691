import React from 'react';
import { DesktopDatePicker, MobileDatePicker, LocalizationProvider } from '@mui/lab';
import { Stack, TextField, InputAdornment } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { FieldAttributes, FormikProps } from 'formik';

import { useMediaHook } from '../../../../../shared/hooks/useMediaHook';

import { useStyles } from './style';

interface IProps {
  form: FormikProps<object>;
  field: FieldAttributes<{
    name: string;
    value: Date;
  }>;
}

export const CustomCalendar: React.FC<IProps> = ({ form, field }) => {
  const { isMobile } = useMediaHook();
  const classes = useStyles();

  const { name, value } = field;
  const { setFieldValue } = form;

  const onChange = (value: Date | null) => {
    setFieldValue(name, value || '');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack className={classes.wrapper}>
        {isMobile ? (
          <MobileDatePicker
            DialogProps={{ className: classes.mobileDialogClasses }}
            aria-label={'Choose data'}
            inputFormat='MMMM dd, yyyy'
            disableMaskedInput={true}
            value={value}
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <CalendarTodayRoundedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        ) : (
          <DesktopDatePicker
            aria-label={'Choose data'}
            showDaysOutsideCurrentMonth={true}
            PaperProps={{
              className: classes.desktopDialogClasses,
            }}
            value={value}
            inputFormat='MMMM dd, yyyy'
            disableMaskedInput={true}
            components={{ OpenPickerIcon: CalendarTodayRoundedIcon }}
            onChange={onChange}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      </Stack>
    </LocalizationProvider>
  );
};
