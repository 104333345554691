import { createTheme } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    desktop: true;
  }
}

export const AppTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 375,
      tablet: 768,
      desktop: 1440,
    },
  },
});

AppTheme.typography.h1 = {
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  fontSize: '60px',
  lineHeight: '68px',
  [AppTheme.breakpoints.down('tablet')]: {
    fontSize: '40px',
    lineHeight: '48px',
  },
};
AppTheme.typography.h2 = {
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  fontSize: '40px',
  lineHeight: '48px',
  fontWeight: 500,
  [AppTheme.breakpoints.down('tablet')]: {
    fontSize: '30px',
    lineHeight: '38px',
    fontWeight: 500,
  },
};

AppTheme.typography.h3 = {
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  fontSize: '30px',
  lineHeight: '40px',
  fontWeight: 500,
  [AppTheme.breakpoints.down('tablet')]: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 500,
  },
};
AppTheme.typography.h4 = {
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  fontSize: '26px',
  lineHeight: '32px',
  fontWeight: 500,
  [AppTheme.breakpoints.down('tablet')]: {
    fontSize: '22px',
    lineHeight: '30px',
    fontWeight: 500,
  },
};
AppTheme.typography.body1 = {
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 400,
  [AppTheme.breakpoints.down('tablet')]: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 400,
  },
};
AppTheme.typography.body2 = {
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: 400,
  [AppTheme.breakpoints.down('tablet')]: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
  },
};
AppTheme.typography.subtitle1 = {
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  fontSize: '18px',
  lineHeight: '21.09px',
  fontWeight: 500,
  [AppTheme.breakpoints.down('tablet')]: {
    fontSize: '16px',
    lineHeight: '18.75px',
    fontWeight: 500,
  },
};
