import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { ColorVar } from '../../../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '20px',

    fontFamily: 'Roboto,sans-serif',
    lineHeight: '40px',
    color: ColorVar.black,
    fontSize: '10px',

    '& .title': {
      margin: 0,
      fontWeight: 'bold',
    },

    '& .map': {
      width: '100%',
      marginTop: '10px',
    },

    '& .properties': {
      marginLeft: '20px',

      '& .details:not(:last-child)': {
        borderBottom: `0.1px solid #e0e0e0`,
      },

      '& .property': {
        marginLeft: '5px',
        color: ColorVar.darkGrey,
        fontWeight: 'lighter',
      }
    },

    '& .description .text': {
      color: ColorVar.darkGrey,
      fontSize: '8px',
      lineHeight: '15px',
      marginTop: '-10px',
    },
  },
}));
