import React, { ReactElement, RefObject, useEffect } from 'react'

import { Form, useFormik, FormikProvider, Field } from 'formik'
import format from 'date-fns/format'

import {
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Box,
  Typography,
  Button,
} from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'

import { CustomAutocomplete } from './custom-autocomplete'
import { CustomCalendar } from './custom-calendar'
import { CustomRadio } from './custom-radio'
import { CommonButton } from '../../../../shared/components/common-button'
import { CommonError } from '../../../../shared/components/common-error'
import { FilterBarHeader } from './filter-bar-header'

import { useStyles } from './style'
import { RequestParams, setHeatmapDataAction, setHeatmapRequestParamsAction } from '../../../../store/heatmap/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useFetchHeatmapMutation } from '../../../../store/api/api'
import { PopoverWrapper } from '../../../../shared/components/popover-wrapper/popover-wrapper'
import { setPopoverStepAction, setSidebarShowAction } from '../../../../store/application/reducer'
import { useMediaHook } from '../../../../shared/hooks/useMediaHook'
import { heatmapRequestParamsSelector } from '../../../../store/heatmap/selectors'
import { varietyTypeOptions } from './varietyType'

interface IProps {
  showLegend?: () => void
  width?: string
}

export const FilterBar: React.FC<IProps> = ({ showLegend, width }) => {
  const classes = useStyles()
  const { isMobile } = useMediaHook()

  const dispatch = useDispatch()
  const [fetchHeatmap] = useFetchHeatmapMutation()
  const requestParams = useSelector(heatmapRequestParamsSelector)

  const initialValues: RequestParams = {
    date: Date(),
    cropType: 'winterWheat',
    varietyType: [],
    risk: 'fhb',
  }

  const formik = useFormik({
    validate: (values) => {
      const errors: { [key: string]: ReactElement } = {}

      if (!values.date) {
        errors.date = <CommonError errorMes={'Please add a date to see results'} />
      }

      if (!values.risk) {
        errors.risk = <CommonError errorMes={'Please add a risk to see results'} />
      }

      if (!values.cropType.length) {
        errors.cropType = <CommonError errorMes={'Please add a crop type to see results'} />
      }

      if (!values.varietyType.length) {
        errors.varietyType = <CommonError errorMes={'Please add a variety to see results'} />
      }

      return errors
    },
    validateOnBlur: false,
    validateOnChange: false,
    initialValues,
    onReset: async (values: RequestParams, formikHelpers) => {
      formikHelpers.setValues(initialValues)
    },
    onSubmit: async (values: RequestParams) => {
      const heatmap = await fetchHeatmap({
        ...values,
        date: format(new Date(values.date), 'yyyy-MM-dd'),
      }).unwrap()

      dispatch(setHeatmapDataAction(heatmap))
      dispatch(
        setHeatmapRequestParamsAction({
          ...values,
          date: values.date.toString(),
        }),
      )

      if (showLegend) {
        showLegend()
      }
      closeMenuHandler()
    },
  })

  useEffect(() => {
    formik.setValues(requestParams)
  }, [])

  const getErrorMessage = () => {
    const [firstError, secondError] = Object.values(formik.errors)
    return secondError ? <CommonError errorMes={'All filters are required to see results'} /> : firstError
  }

  const openPopover = () => {
    dispatch(setPopoverStepAction(1))
  }

  const closeMenuHandler = () => {
    dispatch(setSidebarShowAction(false))
  }

  const submit = () => {
    formik.submitForm()
  }

  return (
    <Container
      sx={{ display: 'flex', width: width }}
      aria-label='Filter bar'
      component={'section'}
      disableGutters={true}
      className={classes.filterBarWrap}
    >
      <FormikProvider value={formik}>
        <Form className={'form'}>
          <Box component={'section'} className={classes.formElements}>
            {!isMobile && (
              <PopoverWrapper step={1} media={['desktop']}>
                <Button
                  ref={React.createRef() as RefObject<HTMLButtonElement>}
                  className={'howItWorksButton'}
                  variant={'outlined'}
                  onClick={openPopover}
                >
                  <HelpOutlineOutlinedIcon className={classes.helpIcon} />
                  Learn how to use the map
                  <ArrowForwardIosRoundedIcon fontSize={'small'} className={classes.arrowIcon} />
                </Button>
              </PopoverWrapper>
            )}
            <FilterBarHeader touched={formik.dirty} closeMenuHandler={closeMenuHandler} />
            <List>
              {filterBarData.map((data, i) => {
                return (
                  <PopoverWrapper key={i} step={data.step} media={data.media}>
                    <ListItem ref={React.createRef() as RefObject<HTMLLIElement>}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreRoundedIcon className={classes.expandIcon} />}
                          aria-controls={'panel3a-content' + `-${i}`}
                          id={'panel3a-header' + `-${i}`}
                        >
                          <Box component={'section'} className={classes.accordHeaderWrap}>
                            <img className={classes.accordIcon} src={data.src} alt={data.alt} />
                            <Typography component={'h2'} variant={'subtitle1'}>
                              {data.accordionName}
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>{data.child}</AccordionDetails>
                      </Accordion>
                    </ListItem>
                  </PopoverWrapper>
                )
              })}
            </List>
            {getErrorMessage()}
          </Box>
          <Box component={'section'} className={classes.btnWrapper}>
            <CommonButton title={'Show Results'} onClick={submit} type={'button'} />
          </Box>
        </Form>
      </FormikProvider>
    </Container>
  )
}

export const cropTypeOptions = [
  { value: 'winterWheat', label: 'Winter wheat' },
  { value: 'springWheat', label: 'Spring wheat' },
  { value: 'barley', label: 'Barley' },
  { value: 'durum', label: 'Durum' },
]

export const riskOptions = [
  { value: 'fhb', label: 'Fusarium Head Blight', disallowCropTypes: [] },
  { value: 'fdk', label: 'Fusarium Damaged Kernel', disallowCropTypes: ['barley', 'winterWheat'] },
  { value: 'deoxy', label: 'Deoxynivalenol', disallowCropTypes: ['barley', 'winterWheat', 'springWheat'] },
]

const filterBarData = [
  {
    accordionName: 'Date',
    src: '/images/icons/calendar.svg',
    alt: 'calendar icon',
    step: 2,
    media: ['desktop'],
    child: <Field name='date' component={CustomCalendar} />,
  },
  {
    accordionName: 'Crop Type',
    src: '/images/icons/crop.svg',
    alt: 'crop icon',
    step: 3,
    media: ['desktop'],
    child: <Field name='cropType' options={cropTypeOptions} component={CustomRadio} />,
  },
  {
    accordionName: 'Variety',
    src: '/images/icons/variety.svg',
    alt: 'variety icon',
    step: 4,
    media: ['desktop'],
    child: <Field name='varietyType' options={varietyTypeOptions} component={CustomAutocomplete} />,
  },
  {
    accordionName: 'Risk',
    src: '/images/icons/warning.svg',
    alt: 'warning icon',
    step: 5,
    media: ['desktop'],
    child: <Field name='risk' options={riskOptions} component={CustomRadio} />,
  },
]
