import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ColorVar } from '../../../../../styles/variables'

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: '100%',
    '& .mapContainer': {
      width: '100%',
      height: '100%',
      position: 'relative'
    },
    '& .weeklyReport, & .exportBtn': {
      color: ColorVar.black,
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.up('tablet')]: {
        width: '168px',
        height: '48px'
      },
      textTransform: 'none',
      marginRight: '10px',
      borderRadius: '5px',
      background: '#fff',
      border: 'none',
      '& .reportImg': {
        width: '17px',
        marginRight: '10px'
      },
      '&:hover': {
        background: '#fff'
      }
    },
    '& .exportImg': {
      width: '22px',
      marginRight: '10px'
    },
    '& .MuiTypography-body1': {
      fontSize: '18px',
      fontWeight: 500,
      color: '#000'
    },
    '& .leaflet-top.leaflet-right': {
      display: 'flex',
      flexDirection: 'row-reverse'
    }
  }
}))
