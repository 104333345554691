import React from 'react';
import { Box, Typography, Container, List, ListItem } from '@mui/material';

import { useMediaHook } from '../../../shared/hooks/useMediaHook';
import { CommonImage } from '../../../shared/components/common-image-component';

import { useStyles } from './style';

export const RiskParamsSection: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useMediaHook();

  return (
    <Container component={'section'} disableGutters={true} className={classes.riskComponentWrap}>
      {isMobile && <CommonImage alt={'wheat'} src={'/images/background/riskSection.jpg'} />}
      <Box component={'section'} className={classes.riskTextWrap}>
        <Typography variant={'h2'} component={'h2'}>
          FHB risk parameters
        </Typography>
        <Typography variant={'body1'} component={'p'}>
          These are quantifiable measures of fusarium head blight pressure and its effects on cereal crops.
        </Typography>
      </Box>
      <List>
        {riskSectionData.map((data, index) => {
          return (
            <ListItem key={index}>
              <Typography variant={'h3'} component={'h3'}>
                {data.heading}
              </Typography>
              <Typography variant={'body2'} component={'p'}>
                {data.text}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
};

const riskSectionData = [
  {
    heading: 'FHB Index',
    text: 'FHB Index (FHBi) is the prevalence of the disease as measured in the field during the filling stage of crop development.  It is the average percentage of infected kernels per infected head multiplied by the average percentage of infected heads in the field.',
  },
  {
    heading: 'Fusarium-damaged kernels',
    text: 'Fusarium-damaged kernels (FDK) result from FHB infection in cereals and cause grain that is chalky and lightweight.  It is a grading factor in cereal crops and reported as the percentage of fusarium-infected kernels in a grain sample.',
  },
  {
    heading: 'Deoxynivalenol',
    text: ' Deoxynivalenol (DON) is a common mycotoxin produced in FHB-infected cereals.  It is measured using the enzyme-linked immunosorbent assay (ELISA) method to determine the amount (ppm) that is present in a grain sample.',
  },
];
