import React from 'react';

import { Icon } from '@mui/material';

interface IconProps {
  src: string;
  alt: string;
}

export const CommonIcon: React.FC<IconProps> = ({ src, alt }) => {
  return (
    <Icon>
      <img alt={alt} src={src} />
    </Icon>
  );
};
