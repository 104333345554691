import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AgreementModalComponent } from '../agreement-modal-component';
import { RootState } from '../../../store/store';
import { setPopoverStepAction, setTermsAction } from '../../../store/application/reducer';


export const TermsOfUse: React.FC = () => {
  const navigate = useNavigate();
  const showTerms = useSelector((state: RootState) => state.application.isShowTerms);
  const dispatch = useDispatch();

  const closeTerms = () => {
    localStorage.removeItem('isShownTerms');
    dispatch(setTermsAction(false));
    dispatch(setPopoverStepAction(0));
    navigate('/');
  };

  const acceptTerms = () => {
    localStorage.setItem('isShownTerms', 'accept');
    dispatch(setTermsAction(false));
  };

  return (
    <AgreementModalComponent
      open={showTerms}
      onClose={closeTerms}
      onAccept={acceptTerms}
      title={'Terms of Use'}
      description={`
        Welcome to the Fusarium Head Blight (FHB) interactive tool.
        This tool provides information on the risk of FHB,
        Fusarium Damaged Kernek (FDK) and Deoxynialenol (DON) across the
        agricultural region of the Canadian Prairies.
        To use this tool, select your province to read
        updates from the provincial specialist.
        Efforts have been make to ensure accuracy of the
        information provided on this tool.
        However, models do not have 100% accuracy so you are encouraged
        to conduct field observations to verify
        the information provided on this tool.
      `}
    />
  );
};
