import React, { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { useMap } from 'react-leaflet'
import leafletImage from 'leaflet-image'

interface IProps {
  setScreenUrl: Dispatch<SetStateAction<string>>
}

export const ScreenComponent: FC<IProps> = ({ setScreenUrl }) => {
  const map = useMap()

  useEffect(() => {
    map &&
      leafletImage(map, function (err: any, canvas: any) {
        setScreenUrl(canvas.toDataURL())
      })
  }, [map])

  return null
}
