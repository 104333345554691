import React, { FC } from 'react';
import { Box, Toolbar } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { LinkButton } from '../link-button';
import { DrawerComponent } from '../drawer-component';
import { useMediaHook } from '../../hooks/useMediaHook';
import { routeConfig } from '../../../config/route-config';

import { useStyles } from './style';
import { RootState } from '../../../store/store';
import { iShowSidebarSelector } from '../../../store/application/selectors';
import { MobileButtonsBar } from '../../../routes/Map/HeatMap/mobile-buttons-bar';

export const ToolbarComponent: FC = () => {
  const classes = useStyles();
  const location = useLocation();

  const { isMobile } = useMediaHook();
  const iShowSidebar = useSelector(iShowSidebarSelector);

  const buttonTitle = isMobile ? 'Use Map' : 'Use Risk Mapping Tool';

  const mapData = useSelector((state: RootState) => state.heatmap.data?.data);

  const isMap = location.pathname === '/map';
  const isHeatLayerShown = isMap && isMobile && Boolean(mapData);
  const isMapButtonShow = location.pathname === '/' || (isMobile && !isHeatLayerShown);

  return (
    <Toolbar component={'section'} className={classes.container}>
      <Box component={'section'} className={classes.toolBar}>
        <Link to={'/'}>
          <img
            alt={'University of Manitoba logo'}
            src={'./images/icons/logoIcon.svg'}
            width={isMobile ? 99 : 122}
            height={isMobile ? 47 : 59}
          />
        </Link>
        <Box component={'nav'} className={classes.navWrapper}>
          {!isMobile && (
            <>
              {routeConfig.map(({ path, name, nestedRoutes }, index) => {
                const nestedPathname = nestedRoutes?.map((nestedRoute) => nestedRoute.path);
                let pathname = [path];
                if (nestedPathname?.length) {
                  pathname = pathname.concat(nestedPathname);
                }
                const isPageActive = pathname.includes(location.pathname);
                if (name === 'Weekly Report') return null;

                return (
                  <Link key={index} to={path} className={isPageActive ? classes.underlinedLink : classes.link}>
                    {name}
                  </Link>
                );
              })}
            </>
          )}
          {isMapButtonShow && <LinkButton link={'/map'} title={buttonTitle} />}
          {isMobile && <DrawerComponent />}
        </Box>
      </Box>
      {isMobile && isMap && !iShowSidebar && <MobileButtonsBar />}
    </Toolbar>
  );
};
