import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Layout } from '../shared/components/layout';
import { FAQ } from './FAQ';
import { Home } from './Home';
import { Map } from './Map';
import { WeeklyReport } from './Map/WeeklyReport';
import { ExportReport } from './Map/ExportReport';
import { useStyles } from './styles';

const App = () => {
  useStyles();

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='/map' element={<Map />} />
        <Route path='/map/weekly-report' element={<WeeklyReport />} />
        <Route path='/map/export-report' element={<ExportReport />} />
        <Route path='/faq' element={<FAQ />} />
        <Route path='*' element={<Home />} />
      </Route>
    </Routes>
  );
};
export default App;
