import React, { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Drawer,
    List,
    ListItem,
    Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useNavigate } from 'react-router-dom';

import { routeConfig } from '../../../config/route-config';

import { useStyles } from './style';
import { useMediaHook } from '../../hooks/useMediaHook';
import { provincesList } from "../../../routes/Map/WeeklyReport";

export const DrawerComponent: React.FC = () => {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const { isMobile } = useMediaHook();
  const classes = useStyles();
  const navigate = useNavigate();

  const menuHandler = () => setDrawerOpened(!drawerOpened);

    const onClickWeeklyReport = (provinceIndex: number) => {
      menuHandler()
      navigate('/map/weekly-report', { state: { provinceIndex } })
    }

  return (
    <Box component={'section'} className={classes.menuButtonWrapper}>
      <Button
        sx={{ width: '21px', minWidth: '21px' }}
        type='button'
        aria-label='Open menu button'
        onClick={menuHandler}
      >
        <MenuIcon className={classes.navButton} />
      </Button>
      <Drawer PaperProps={{ classes: { root: classes.drawer } }} open={drawerOpened}>
        <Box className={classes.menuHeader}>
          <Link to={'/'}>
            <img
              alt={'logo'}
              src={'./images/icons/logoIcon.svg'}
              width={isMobile ? 99 : 122}
              height={isMobile ? 47 : 59}
            />
          </Link>
          <Button
            sx={{ width: '21px', minWidth: '21px' }}
            type='button'
            aria-label={'Close menu button'}
            name='Close menu button'
            onClick={menuHandler}
          >
            <CloseIcon className={classes.navButton} />
          </Button>
        </Box>
        <List component={'ul'}>
          {routeConfig.map(({ path, name }, index) => {
            if (path === '/map/weekly-report') {
              return (
                <Container component={'section'} key={index}>
                  <Accordion disableGutters={true} sx={{ boxShadow: 'none' }}>
                    <AccordionSummary sx={{ padding: 0 }}>
                      <Typography variant='h6' component='p'>
                        {name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {provincesList.map((province, provinceIndex) => {
                        if (!provinceIndex) return null
                        return (
                          <ListItem
                            key={provinceIndex}
                            onClick={() => onClickWeeklyReport(provinceIndex)}
                            sx={{ padding: '8px 0' }}
                          >
                            <Link to={path} className={classes.typography}>
                              <Typography variant='h6' component='p'>
                                {province}
                              </Typography>
                            </Link>
                          </ListItem>
                        )
                      })}
                    </AccordionDetails>
                  </Accordion>
                </Container>
              )
            }
            return (
              <ListItem key={index} onClick={menuHandler}>
                <Link to={path} className={classes.typography}>
                  <Typography variant='h6' component='p'>
                    {name}
                  </Typography>
                </Link>
              </ListItem>
            )
          })}
        </List>
      </Drawer>
    </Box>
  );
};
