import React from 'react';

import { Box, CircularProgress } from '@mui/material';
import { useStyles } from './styles';

export const LoaderComponent: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <CircularProgress />
    </Box>
  );
};
