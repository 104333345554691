import React, { FC } from 'react';

import { Drawer } from '@mui/material';
import { useSelector } from 'react-redux';

import { useStyles } from './style';
import { FilterBar } from '../../../routes/Map/HeatMap/filter-bar';
import { iShowSidebarSelector } from '../../../store/application/selectors';

interface IProps {
  showLegend?: () => void;
}

export const DrawerFilterBar: FC<IProps> = ({ showLegend }) => {
  const classes = useStyles();
  const iShowSidebar = useSelector(iShowSidebarSelector);

  return (
    <Drawer className={classes.drawer} open={iShowSidebar}>
      <FilterBar showLegend={showLegend} />
    </Drawer>
  );
};
