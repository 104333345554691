import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { ColorVar } from '../../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => ({
  sectionWrap: {
    flexDirection: 'column',
    [theme.breakpoints.between('tablet', 'desktop')]: {
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      margin: '100px 0px 100px',
    },
    '& 	.MuiListItem-root': {
      width: '100%',
      margin: '0 auto',
      [theme.breakpoints.between(0, 'tablet')]: {
        marginBottom: '30px',
      },
      [theme.breakpoints.up('tablet')]: {
        paddingLeft: 0,
      },
    },
    '& p': {
      [theme.breakpoints.between(0, 'tablet')]: {
        width: '280px',
      },
      [theme.breakpoints.up('tablet')]: {
        width: '400px',
      },
    },
    '& h2': {
      [theme.breakpoints.between(0, 'tablet')]: {
        margin: '30px 20px',
      },
      [theme.breakpoints.up('tablet')]: {
        margin: '30px 0px',
      },
    },
  },
  listIcon: {
    textAlign: 'center',
    fontWeight: 500,
    width: '93px',
    color: ColorVar.black,
    [theme.breakpoints.between(0, 'tablet')]: {
      width: '26px',
      height: '26px',
      fontSize: '17px',
      lineHeight: '26px',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '45px',
      height: '45px',
      lineHeight: '45px',
    },
    backgroundColor: ColorVar.orange,
    borderRadius: 100,
  },
  listWrap: {
    [theme.breakpoints.down('desktop')]: {
      margin: '0 auto',
    },
    paddingBottom: '30px',
  },
  iconWrap: {
    [theme.breakpoints.up('tablet')]: {
      width: '98px',
    },
  },
  mapPicWrap: {
    backgroundColor: ColorVar.lightGreyV2,
    width: '100%',
    [theme.breakpoints.between(0, 'tablet')]: {
      height: '393px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '60px 0px',
    },
    [theme.breakpoints.up('desktop')]: {
      width: '714px',
      marginRight: '58px',
      borderRadius: '10px',
    },
  },
}));
