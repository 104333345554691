import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Popover, Typography, Box, IconButton } from '@mui/material';
import { PopoverOrigin } from '@material-ui/core';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { CommonButton } from '../common-button';
import { useStyles } from './styles';
import { useMediaHook } from '../../hooks/useMediaHook';
import { stepSelector } from '../../../store/application/selectors';
import { setPopoverStepAction, setSidebarShowAction } from '../../../store/application/reducer';

type IProps = {
  children: any;
  step?: number;
  media?: string[];
};
export const PopoverWrapper: FC<IProps> = ({ children, step, media }) => {
  const anchorEl = children.ref.current;
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const { isMobile } = useMediaHook();
  const currentStep = useSelector(stepSelector);
  const [anchorOrigin, setAnchorOrigin] = useState<PopoverOrigin>({
    vertical: 'top',
    horizontal: 'right',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(false);
    if (
      step === currentStep &&
      ((isMobile && media && media.includes('mobile')) || (!isMobile && media && media.includes('desktop')))
    ) {
      setOpen(true);
    }
  }, [currentStep, media]);

  useEffect(() => {
    switch (true) {
      case isMobile && step && [1].includes(step):
        setAnchorOrigin({
          vertical: 'bottom',
          horizontal: 'left',
        });
        break;
      case step === 6:
        setAnchorOrigin({
          vertical: 'bottom',
          horizontal: 'right',
        });
        break;
    }
  }, [currentStep]);

  const handleClose = () => {
    dispatch(setPopoverStepAction(0));
  };

  const handleCloseByIconClick = () => {
    localStorage.setItem('isShownPopover', 'accept');
    dispatch(setPopoverStepAction(0));
  };

  const getClasses = () => {
    const popoverClasses = ['steps', classes.wrapper];

    if (isMobile) {
      popoverClasses.push('mobile');
    } else {
      popoverClasses.push('desktop');
    }

    switch (step) {
      case 1:
        popoverClasses.push('step-one');
        break;
      case 2:
        popoverClasses.push('step-two');
        break;
      case 3:
        popoverClasses.push('step-three');
        break;
      case 4:
        popoverClasses.push('step-four');
        break;
      case 5:
        popoverClasses.push('step-five');
        break;
      case 6:
        popoverClasses.push('step-six');
        break;
    }

    return popoverClasses.join(' ');
  };

  const next = () => {
    if (isMobile && step === 1) {
      dispatch(setSidebarShowAction(true));
    }

    if (isMobile && step === 5) {
      return;
    }

    dispatch(setPopoverStepAction(Number(step) + 1));
  };

  return (
    <>
      {[2,3,4,5].includes(step!) && currentStep === step && isMobile && (
        <Box component={'section'} className={classes.stepInSidebarMobile}>
          <Typography component={'p'} variant={'body2'}>
            {
              (step === 2 && 'Select the date (Please note, the choice will be limited to dates prior to the current date).') ||
              (step === 3 && 'Select the crop you would like to analyze on your map view.') ||
              (step === 4 && 'Select the variety you would like to analyze on your map view.') ||
              (step === 5 && 'Risk will display the FHB risk damage and it’s effects on cereal crops as a heatmap.')
            }
          </Typography>
          <Box component={'section'} className={classes.nextStepWrap}>
            <Typography component={'p'} variant={'body2'}>
              Step {step} of 6
            </Typography>
            <CommonButton title={'Next'} onClick={next} />
          </Box>
        </Box>
      )}

      {step && anchorEl && (
        <Popover
          className={getClasses()}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
        >
          <Box component={'div'} className={step === 6 ? classes.greyArrowTop : classes.greyArrow} />
          <Box component={'section'} className={classes.headerWrap}>
            <Typography component={'h4'} variant={'h4'}>
              {
                (step === 1 && 'Filter your view') ||
                (step === 2 && 'Select the date') ||
                (step === 3 && 'Select your crop of interest') ||
                (step === 4 && 'Select the variety of interest') ||
                (step === 5 && 'Select the type of FHB risk') ||
                (step === 6 && 'Create a report')
              }
            </Typography>
            <IconButton onClick={handleCloseByIconClick}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          <Typography component={'p'} variant={'body2'}>
            {
              (step === 1 && 'Select filters to narrow down your desired map view.') ||
              (step === 2 && 'Please note, the choice will be limited to dates prior to the current date.') ||
              (step === 3 && 'Select the crop you would like to analyze on your map view.') ||
              (step === 4 && 'Select the variety you would like to analyze on your map view.') ||
              (step === 5 && 'Select which risk type you would like to analyze on your map view.') ||
              (step === 6 && 'Export your map view with the filters and risk analysis you selected.')
            }
          </Typography>
          <Box component={'section'} className={classes.nextStepWrap}>
            <Typography component={'p'} variant={'body2'}>
              Step {step} of 6
            </Typography>
            <CommonButton title={step === 6 ? 'Close' : 'Next'} onClick={next} />
          </Box>
        </Popover>
      )}
      {children}
    </>
  );
};
