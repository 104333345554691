import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { ColorVar } from '../../../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => ({
  box: {
    [theme.breakpoints.between(0, 'tablet')]: {
      padding: '16px 0 0 0',
    },
  },
  button: {
    '&.MuiButton-text': {
      fontSize: '18px',
      color: ColorVar.black,
      textTransform: 'none',
    },
  },
  downloadList: {
    '& ul': {
      backgroundColor: ColorVar.lightGrey,

      '& li': {
        fontSize: '18px',
      }
    }
  },
  print: {
    '&.MuiButton-text': {
      marginLeft: 30,
    },
  },
  downloadMenuItem:{
    '&.MuiMenuItem-root': {
      width: 190,
      marginLeft: 15,
      color: ColorVar.black,
    },
  }
}));
